import { useContext } from 'react'
import * as S from './styles'
import { CardContext } from 'src/contexts/Card/CardContext'
import { useModal } from 'src/contexts/Modal/ModalContext'
// import Reschedule from '../Reschedule'
import Schedule from '../Schedule'
import { AutoCompleteName } from '../AutoCompleteName'
import { MemberProvider } from 'src/contexts/Members/MembersProvider'
import { SetupProvider } from 'src/contexts/Setups/SetupProvider'
import { DateProvider } from 'src/contexts/Date/DateContext'
import { SessionClientProvider } from 'src/contexts/SessionClient/SessionClientProvider'
import { SessionProvider } from 'src/contexts/Sessions/SessionProvider'
import { useSetupContext } from 'src/contexts/Setups/SetupContext'
import MembersContext from 'src/contexts/Members/MembersContext'
import SchedulePage from 'src/pages/SchedulePage'

const Card = () => {
  const { card } = useContext(CardContext) || {}
  const { openModal } = useModal()
  const { selectedMemberId } = useContext(MembersContext)

  const handleDetailsClick = (
    classDetails: { aluno: string; id: string }[] | string
  ) => {
    console.log('classDetails:', classDetails)
    if (typeof classDetails === 'string') {
      openModal(
        <S.DetailsContainer>
          <S.ClassDetailsString>
            <p>{classDetails}</p>

            <S.ActionButton onClick={() => handleAddParticipantClick([])}>
              Adicionar
            </S.ActionButton>
          </S.ClassDetailsString>
        </S.DetailsContainer>
      )
    } else if (Array.isArray(classDetails)) {
      const detailsString = classDetails
        .sort((a, b) => a.aluno.localeCompare(b.aluno))
        .map((detail, index) => (
          <S.DetailItem key={index}>
            <S.Content>
              <S.Aluno>Aluno:</S.Aluno>
              <p>{detail.aluno}</p>
            </S.Content>
            <S.ActionButton
              onClick={() => handleRescheduleClick(detail.aluno, detail.id)}
            >
              Reagendar
            </S.ActionButton>
          </S.DetailItem>
        ))

      openModal(
        <S.DetailsContainer>
          {detailsString}
          {classDetails.length > 0 && classDetails.length < 5 ? (
            <S.AddButton>
              <S.ActionButton
                onClick={() => handleAddParticipantClick(classDetails)}
              >
                Adicionar
              </S.ActionButton>
            </S.AddButton>
          ) : classDetails.length === 5 ? (
            <S.Limited>Limite de alunos alcançados</S.Limited>
          ) : null}
        </S.DetailsContainer>
      )
    } else {
      openModal(
        <S.DetailsContainer>
          <p>Erro ao carregar detalhes da aula</p>
        </S.DetailsContainer>
      )
    }
  }

  const handleRescheduleClick = (aluno: string, id: string) => {
    openModal(
      <S.DetailsContainer>
        <SchedulePage aluno={aluno} id={id} showHeader={false} />
      </S.DetailsContainer>
    )
  }

  const handleAddParticipantClick = (
    classDetails: { aluno: string; id: string }[]
  ) => {
    const addedMember = classDetails.some(
      (detail) => detail.id === selectedMemberId
    )

    console.log('addedMember', addedMember)

    if (addedMember) {
      openModal(
        <S.DetailsContainer>
          <p>Este aluno já foi adicionado à aula</p>
        </S.DetailsContainer>
      )
      return
    }

    const SessionComponentWithSetup: React.FC = () => {
      const { setups } = useSetupContext()

      return (
        <SessionProvider setups={setups}>
          <Schedule />
        </SessionProvider>
      )
    }

    openModal(
      <S.DetailsContainer>
        <MemberProvider>
          <AutoCompleteName />
          <DateProvider>
            <SetupProvider>
              <SessionClientProvider>
                <SessionComponentWithSetup />
              </SessionClientProvider>
            </SetupProvider>
          </DateProvider>
        </MemberProvider>
      </S.DetailsContainer>
    )
  }

  return (
    <>
      {card &&
        card.map(({ time, class: className, classDetails }) => (
          <S.Card key={time}>
            <h3>{time}</h3>
            <h4>{className}</h4>
            <S.DetailsButton
              onClick={() =>
                handleDetailsClick(
                  classDetails as { aluno: string; id: string }[] | string
                )
              }
            >
              Detalhes
            </S.DetailsButton>
          </S.Card>
        ))}
    </>
  )
}

export default Card
