import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Logo from '../../assets/Logo.png'

import * as S from './styles'
import { AuthContext } from 'src/contexts/Auth/AuthContext'
import { IoCloseCircleOutline, IoReorderThreeSharp } from 'react-icons/io5'
import Onboarding from '../Onboarding'

const Header = () => {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const [menuOpen, setMenuOpen] = useState(false)
  const [showOnboarding, setShowOnboarding] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const handleSize = () => {
    if (window.innerWidth > 768 && !menuOpen) {
      setMenuOpen(true)
    }
    if (window.innerWidth <= 426 && menuOpen) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleSize)
    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [menuOpen])

  useEffect(() => {
    if (showOnboarding) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [showOnboarding])

  const isActive = (path: string) => location.pathname === path

  const handleLogout = () => {
    auth.signout()
  }

  const startOnboarding = () => {
    setShowOnboarding(true)
  }

  return (
    <>
      <S.Container>
        <S.Logo id="logo" src={Logo} alt="Logo" />
        <p>Olá {auth.user?.email}</p>
        <S.Nav $isOpen={menuOpen}>
          <S.ContentMobile onClick={toggleMenu}>
            {menuOpen ? (
              <IoCloseCircleOutline color="#a95efa" size={32} />
            ) : (
              <IoReorderThreeSharp color="#a95efa" size={32} />
            )}
          </S.ContentMobile>
          {menuOpen || window.innerWidth > 769 ? (
            <ul>
              <li>
                <S.LinkTo
                  id="dashboard"
                  to="/dashboard"
                  $isActive={isActive('/dashboard')}
                >
                  Home
                </S.LinkTo>
              </li>
              <li>
                <S.LinkTo id="setup" to="/setup" $isActive={isActive('/setup')}>
                  Horários
                </S.LinkTo>
              </li>
              <li>
                <S.LinkTo
                  id="schedule"
                  to="/schedule"
                  $isActive={isActive('/schedule')}
                >
                  Agendar
                </S.LinkTo>
              </li>
              <li>
                <S.LinkTo
                  id="members"
                  to="/members"
                  $isActive={isActive('/members')}
                >
                  Alunos
                </S.LinkTo>
              </li>
              <li>
                <S.LinkTo
                  onClick={handleLogout}
                  to="/login"
                  $isActive={isActive('/login')}
                >
                  Logout
                </S.LinkTo>
              </li>
            </ul>
          ) : (
            <></>
          )}
        </S.Nav>
      </S.Container>
      <S.Hr />
      <button onClick={startOnboarding}>Iniciar Tour</button>

      {showOnboarding && <Onboarding />}
    </>
  )
}

export default Header
