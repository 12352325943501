/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from 'react'
import ReactJoyride, { Step } from 'react-joyride'
import { AuthContext } from 'src/contexts/Auth/AuthContext'
import variables from 'src/styles/variables'
import home from '../../assets/Home.jpg'
import setup from '../../assets/Horários.jpg'
import schedule from '../../assets/Schedule.jpg'
import clients from '../../assets/Clients.jpg'
import { OnboardingImg } from './styles'
const steps: Step[] = [
  {
    target: '#date',
    content: (
      <div>
        <OnboardingImg src={home} />
        <p>
          Home - Aqui é o início, onde você encontra os horários disponíveis e
          pode navegar pelas datas. Clicando em Detalhes, vocè poderá ver os
          alunos agendados e também poderá refazer os agendamentos, caso
          necessário.
        </p>
      </div>
    ),
    disableBeacon: true
  },
  {
    target: '#date',
    content: (
      <div>
        <OnboardingImg src={setup} />
        <p>
          Horários - Aqui você pode cadastrar seus dias e horários de aula. Caso
          seja necessário, pode alterá-los também.
        </p>
      </div>
    )
  },
  {
    target: '#date',
    content: (
      <div>
        <OnboardingImg src={schedule} />
        <p>
          Agendar - Aqui você irá agendar seus alunos nas aulas disponíveis.
        </p>
      </div>
    )
  },
  {
    target: '#date',
    content: (
      <div>
        <OnboardingImg src={clients} />
        <p>
          Cadastre seus alunos e edite suas informações. Caso necessário, pode
          excluir os dados também
        </p>
      </div>
    )
  }
]

const Onboarding = () => {
  const auth = useContext(AuthContext)
  const [run, setRun] = useState(true)

  const handleOnboardingCallback = (data: any) => {
    if (data.status === 'finished' || data.status === 'skipped') {
      setRun(false)
      auth.setShowOnboarding(false)
    }
  }

  return (
    <ReactJoyride
      steps={steps}
      run={run}
      continuous
      hideCloseButton
      disableOverlayClose
      disableCloseOnEsc
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: `${variables.purple}`
        },
        tooltip: {
          borderRadius: 10,
          backgroundColor: variables.purple,
          color: '#fff',
          maxWidth: '90%',
          width: '600px',
          margin: '0 30px'
        },
        tooltipContainer: {
          textAlign: 'center'
        },
        spotlight: {
          backgroundColor: 'transparent'
        },
        overlay: {
          backgroundColor: 'transparent'
        }
      }}
      floaterProps={{
        styles: {
          arrow: {
            display: 'none'
          },
          floater: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }
        },
        disableAnimation: true
      }}
      disableOverlay={true}
      callback={handleOnboardingCallback}
    />
  )
}

export default Onboarding
