import Header from 'src/components/Header'
import * as S from './styles'
import Card from 'src/components/Card'
import { CardProvider } from 'src/contexts/Card/CardContext'
import { ModalProvider } from 'src/contexts/Modal/ModalContext'
import Modal from 'src/components/Modal'
import { DateProvider, useDate } from 'src/contexts/Date/DateContext'
import { SessionProvider } from '../../contexts/Sessions/SessionProvider'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './datePickerStyles.css'
import { ptBR } from 'date-fns/locale'
import { MemberProvider } from 'src/contexts/Members/MembersProvider'
import Onboarding from 'src/components/Onboarding'
import { AuthContext } from 'src/contexts/Auth/AuthContext'
import { useContext } from 'react'

const DashboardContent = () => {
  const auth = useContext(AuthContext)
  const { selectedDate, setSelectedDate } = useDate()

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const adjustedDate = new Date(date)
      adjustedDate.setHours(0, 0, 0, 0)
      setSelectedDate(adjustedDate)
    } else {
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      setSelectedDate(currentDate)
    }
  }

  return (
    <>
      {auth.showOnboarding && <Onboarding />}
      <Header />
      <S.Date>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          id="date"
          dateFormat="dd/MM/yyyy"
          locale={ptBR}
          className="date-picker"
        />
        <hr />
      </S.Date>
      <S.Container>
        <MemberProvider>
          <SessionProvider setups={[]}>
            <ModalProvider>
              <S.Cards>
                <CardProvider>
                  <Card />
                  <Modal />
                </CardProvider>
              </S.Cards>
            </ModalProvider>
          </SessionProvider>
        </MemberProvider>
      </S.Container>
    </>
  )
}

const Dashboard = () => (
  <DateProvider>
    <DashboardContent />
  </DateProvider>
)

export default Dashboard
